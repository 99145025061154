import React from "react";
import { Layout } from "antd";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import SpeechState from "@app/pages/VideoCall/layout/video/SpeechState";
import VideoPlaceholder from "@app/pages/VideoCall/layout/video/VideoPlaceholder";
import ViewerCount from "@app/pages/VideoCall/layout/video/ViewerCount";
import FullScreenButton from "@app/pages/VideoCall/layout/video/FullScreenButton";
import InterpreterVideo from "@app/pages/VideoCall/layout/video/InterpreterVideo";
import SubtitleContainer from "@app/pages/VideoCall/layout/video/SubtitleContainer";

const { Content } = Layout;

const VideoPlayer = () => {
  const {
    state: { guideVideoClassName },
    role,
    refs: { container, tourGuideVideo },
  } = useVideoProvider();

  return (
    <Content className="main-container">
      <div className="tour-guide" ref={container}>
        {role === "guide" && <SpeechState />}
        <video
          ref={tourGuideVideo}
          className={`tour-guide-video ${guideVideoClassName}`}
          muted={role === "guide"}
          playsInline
          autoPlay
        />
        {guideVideoClassName === "hide" && <VideoPlaceholder />}
        {role === "guide" && <ViewerCount />}
        {guideVideoClassName !== "hide" && <SubtitleContainer />}
        <FullScreenButton />
        <InterpreterVideo />
      </div>
    </Content>
  );
};

export default VideoPlayer;
