import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { LanguageOptions } from "@app/pages/VideoCall/config/LanguageOptions";
import { Form, TreeSelect } from "antd";
import React from "react";
import { useVh } from "@app/hooks/useVh";

const SpeakerLanguage = () => {
  const {
    state: { labelCol },
    refs: { speakerLanguage },
    role,
    methods: { onSpeakerLanguageChange },
  } = useVideoProvider();
  const vh = useVh();
  return (
    <Form.Item label="Speaker language" labelCol={labelCol}>
      <TreeSelect
        value={speakerLanguage.current || undefined}
        treeData={LanguageOptions}
        onChange={onSpeakerLanguageChange}
        disabled={role === "guide"}
        treeDefaultExpandAll
        listItemHeight={3 * vh}
        listHeight={40 * vh}
      />
    </Form.Item>
  );
};

export default SpeakerLanguage;
