import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useCallback } from "react";

export const useOnMute = () => {
  const {
    setState,
    refs: { stream },
  } = useVideoProvider();
  return useCallback(() => {
    if (stream.current) {
      stream.current.getTracks()[0].enabled =
        !stream.current.getTracks()?.[0].enabled;
      setState((prevState) => ({
        ...prevState,
        isMute: !prevState.isMute,
      }));
    }
  }, [stream, setState]);
};
