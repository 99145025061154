import { DownloadIcon } from "@app/assets/icon";
import { useOnDownload } from "@app/pages/VideoCall/context/hooks/useOnDownload";
import { Button } from "antd";
import React from "react";

const DownloadTranscript = () => {
  const onDownload = useOnDownload();

  return (
    <Button
      type="primary"
      onClick={onDownload}
      size="large"
      icon={<DownloadIcon />}
      block
      className="download-button"
    >
      Download Transcript
    </Button>
  );
};

export default DownloadTranscript;
