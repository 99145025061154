import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import React from "react";

const InterpreterVideo = () => {
  const {
    state: { interpreterVideoClassName },
    role,
    refs: { signInterpreter },
  } = useVideoProvider();

  return (
    <div className="sign-interpreter">
      <video
        ref={signInterpreter}
        id="sign-interpreter-video"
        muted={role === "interpreter"}
        className={`${interpreterVideoClassName}`}
      />
    </div>
  );
};

export default InterpreterVideo;
