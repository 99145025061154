import {
  AuthState,
  AuthValue,
  ChangeInitialPasswordValues,
  ForgotPasswordValues,
  ResetPasswordValues,
  SigninValues,
  SignupValues,
} from "@app/context/types";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import { notification } from "antd";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const initialState: AuthState = {
  loading: true,
  error: null,
  user: null,
  userAttributes: [],
};

const pool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID || "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
});

export const AuthContext = createContext<AuthValue | undefined>(undefined);

export const AuthProvider: FC = ({ children }) => {
  const [state, setState] = useState<AuthState>(initialState);

  const onSignup = useCallback(async (values: SignupValues) => {
    return new Promise<ISignUpResult | undefined>((resolve, reject) => {
      pool.signUp(
        values.email,
        values.password,
        [
          new CognitoUserAttribute({
            Name: "email",
            Value: values.email,
          }),
        ],
        [],
        (error, result) => {
          if (error) reject(error);
          resolve(result);
        }
      );
    });
  }, []);

  const onSignout = useCallback(async () => {
    await new Promise<void>((resolve) => {
      const user = pool.getCurrentUser();
      if (user) {
        user.signOut(resolve);
      } else {
        resolve();
      }
    });
    setState({
      loading: false,
      error: null,
      user: null,
      userAttributes: [],
    });
  }, []);

  const onSignin = useCallback(async (values: SigninValues) => {
    const cognitoUser = new CognitoUser({
      Pool: pool,
      Username: values.email,
    });
    return new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: values.email,
          Password: values.password,
        }),
        {
          onSuccess: async (session) => {
            const userAttributes = await getUserAttributes(cognitoUser);
            setState({
              loading: false,
              error: null,
              user: cognitoUser,
              userAttributes,
            });
            resolve(session);
          },
          onFailure: reject,
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            values.newPasswordRequired();
          },
        }
      );
    });
  }, []);

  const onForgotPassword = useCallback(async (values: ForgotPasswordValues) => {
    const cognitoUser = new CognitoUser({
      Pool: pool,
      Username: values.email,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: resolve,
        onFailure: reject,
      });
    });
  }, []);

  const onChangeInitialPassword = useCallback(
    async (values: ChangeInitialPasswordValues) => {
      const cognitoUser = new CognitoUser({
        Pool: pool,
        Username: values.email,
      });
      return new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser.authenticateUser(
          new AuthenticationDetails({
            Username: values.email,
            Password: values.oldPassword,
          }),
          {
            onSuccess: async (session) => {
              const userAttributes = await getUserAttributes(cognitoUser);
              setState({
                loading: false,
                error: null,
                user: cognitoUser,
                userAttributes,
              });
              resolve(session);
            },
            onFailure: reject,
            newPasswordRequired: (userAttributes, requiredAttributes) => {
              delete userAttributes.email_verified;
              cognitoUser.completeNewPasswordChallenge(
                values.newPassword,
                userAttributes,
                {
                  onSuccess: async (session) => {
                    const userAttributes = await getUserAttributes(cognitoUser);
                    setState({
                      loading: false,
                      error: null,
                      user: cognitoUser,
                      userAttributes,
                    });
                    resolve(session);
                  },
                  onFailure: reject,
                }
              );
            },
          }
        );
      });
    },
    []
  );

  const getUserAttributes = async (
    cognitoUser: CognitoUser
  ): Promise<CognitoUserAttribute[]> => {
    const userAttributes = await new Promise<CognitoUserAttribute[]>(
      (resolve, reject) =>
        cognitoUser.getUserAttributes((err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result || []);
          }
        })
    );
    return userAttributes;
  };
  const onResetPassword = async (
    values: ResetPasswordValues
  ): Promise<void> => {
    const cognitoUser = new CognitoUser({
      Pool: pool,
      Username: values.email,
    });
    return new Promise<void>((resolve, reject) =>
      cognitoUser.confirmPassword(values.verificationCode, values.newPassword, {
        onSuccess: () => resolve(),
        onFailure: reject,
      })
    );
  };

  useEffect(() => {
    const user = pool.getCurrentUser();
    if (user) {
      const getSession:
        | ((err: Error, session: null) => void)
        | ((err: null, session: CognitoUserSession) => void) = async (
        err: Error | null,
        session: CognitoUserSession | null
      ) => {
        if (err) {
          setState({
            loading: false,
            user: null,
            error: null,
            userAttributes: [],
          });
        } else {
          const isValid = session?.isValid();
          if (isValid) {
            try {
              const userAttributes = await getUserAttributes(user);
              setState({
                loading: false,
                user,
                error: null,
                userAttributes,
              });
            } catch (error) {
              notification.error({
                message: error.message,
              });
            }
          } else {
            setState({
              loading: false,
              user: null,
              error: null,
              userAttributes: [],
            });
          }
        }
      };
      user.getSession(getSession);
    } else {
      setState({
        loading: false,
        user: null,
        error: null,
        userAttributes: [],
      });
    }
  }, []);

  const value = {
    state,
    methods: {
      onSignup,
      onSignin,
      onSignout,
      onChangeInitialPassword,
      onForgotPassword,
      onResetPassword,
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
