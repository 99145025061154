export const PositionOptions = [
  {
    value: "Full",
    label: "Full",
  },
  {
    value: "Bottom",
    label: "Bottom",
  },
  {
    value: "Right",
    label: "Right",
  },
  {
    value: "Left",
    label: "Left",
  },
  {
    value: "Top",
    label: "Top",
  },
];

export const getPosition = (
  e: string
): {
  top?: string | number;
  bottom?: string | number;
  width: string;
  right?: string | number;
  height?: string | number;
} => {
  switch (e) {
    case "Bottom":
      return {
        bottom: "8%",
        width: "100%",
      };
    case "Left":
      return {
        right: "50%",
        width: "50%",
      };
    case "Right":
      return {
        right: 0,
        width: "50%",
      };
    case "Top":
      return {
        top: "0%",
        width: "100%",
      };
    case "Full":
      return {
        top: "0%",
        width: "100%",
        height: "100%",
      };
    default:
      return {
        bottom: "10%",
        width: "90%",
      };
  }
};
