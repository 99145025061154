import { useVh } from "@app/hooks/useVh";
import { FontOptions } from "@app/pages/VideoCall/config/FontOptions";
import { useOnFontChange } from "@app/pages/VideoCall/context/hooks/useOnFontChange";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Form, Select } from "antd";
import React from "react";

const FontSelect = () => {
  const {
    state: {
      labelCol,
      subtitle: { font },
    },
  } = useVideoProvider();
  const onFontChange = useOnFontChange();
  const vh = useVh();

  return (
    <Form.Item label="Subtitle fonts" labelCol={labelCol}>
      <Select
        options={FontOptions}
        value={font}
        onChange={onFontChange}
        listHeight={30 * vh}
        listItemHeight={3 * vh}
      />
    </Form.Item>
  );
};

export default FontSelect;
