import { useForceUpdate } from "@app/hooks/useForceUpdate";
import { LanguageOptions } from "@app/pages/VideoCall/config/LanguageOptions";
import { initialState } from "@app/pages/VideoCall/context/initialState";
import { useGuideVideoRef } from "@app/pages/VideoCall/context/refs/useGuideVideoRef";
import { useInterpreterVideoRef } from "@app/pages/VideoCall/context/refs/useInterpreterVideoRef";
import { useUserVideoRef } from "@app/pages/VideoCall/context/refs/useUserVideoRef";
import {
  SetVideoState,
  SocketEvents,
  SpeechStatus,
  VideoCallMethods,
  VideoCallRefs,
  VideoCallState,
} from "@app/pages/VideoCall/context/types";
import { createMediaStreamWithMic } from "@app/utils/createMediaStream";
import Peer, { CallOption } from "peerjs";
import React, {
  createContext,
  createRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";

type Role = "guide" | "interpreter" | null;
type VideoCallProps = { roomId: string };

const VideoContext = createContext<{
  state: VideoCallState;
  setState: SetVideoState;
  methods: VideoCallMethods;
  role: string | null;
  refs: VideoCallRefs;
  status: SpeechStatus;
}>({
  state: initialState,
  setState: () => {},
  methods: {
    onSpeakerLanguageChange: (arg: string) => {},
    onStartStream: async () => {},
    onStopStream: () => {},
  },
  refs: {
    stream: createRef<MediaStream>(),
    container: createRef<HTMLDivElement>(),
    signInterpreter: createRef<HTMLVideoElement>(),
    tourGuideVideo: createRef<HTMLVideoElement>(),
    userVideo: createRef<HTMLVideoElement>(),
    speakerLanguage: createRef<string>(),
  },
  role: null,
  status: SpeechStatus.UNKNOWN,
});

const socketServer = "socket.web-subtitles.donutrobotics.co.jp";
const peerServer = "peer.web-subtitles.donutrobotics.co.jp";

const VideoCallProvider: FC<VideoCallProps> = ({ roomId, children }) => {
  const peer = useMemo(
    () =>
      new Peer(undefined, {
        secure: true,
        host: peerServer,
        path: "/myapp",
      }),
    []
  );
  const socket = useMemo(
    () =>
      io(`https://${socketServer}`, {
        secure: true,
        transports: ["websocket"],
      }),
    []
  );
  const [state, setState] = useState<VideoCallState>(initialState);
  const role = useMemo(
    () => new URLSearchParams(window.location.search).get("role") as Role,
    []
  );
  const container = useRef<HTMLDivElement>(null);
  const { signInterpreter, onInterpreterStream, onInterpreterDisconnected } =
    useInterpreterVideoRef(setState);
  const { tourGuideVideo, onGuideStream, onGuideDisconnected } =
    useGuideVideoRef(setState);
  const { userVideo, onUserStream, onUserDisconnected } = useUserVideoRef();

  const recognition = useRef<any>(undefined);
  const isRecognizing = useRef(false);
  const stream = useRef<MediaStream | undefined>(undefined);
  const timeoutID = useRef(0);
  const textClearSecond = useRef(5);
  const speakerLanguage = useRef("ja-JP");
  const forceUpdate = useForceUpdate();
  const [status, setStatus] = useState<SpeechStatus>(SpeechStatus.UNKNOWN);
  const isFullyStopped = useRef(true);

  const clearTimeoutForClearText = () => {
    if (timeoutID.current) clearTimeout(timeoutID.current);
  };
  const setTimeoutForClearText = useCallback(() => {
    clearTimeoutForClearText();
    timeoutID.current = window.setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        resultText: "",
      }));
      timeoutID.current = 0;
    }, textClearSecond.current * 1000);
  }, []);

  const myStream = useCallback(async (): Promise<MediaStream> => {
    if (stream.current) return stream.current;
    if (role && ["guide", "interpreter"].includes(role)) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      return stream.current;
    } else {
      stream.current = await createMediaStreamWithMic();
      return stream.current;
    }
  }, [role]);

  const onPeerCall = useCallback(
    async (call: Peer.MediaConnection) => {
      await myStream();
      call.answer(stream.current); // Answer the call with an A/V stream.
      const callerRole = call.metadata.role;
      if (callerRole === "guide") {
        call.on("stream", (remoteStream) => {
          onGuideStream(remoteStream);
        });
        call.on("close", () => {
          onGuideDisconnected();
        });
      } else if (callerRole === "interpreter") {
        call.on("stream", (remoteStream) => {
          onInterpreterStream(remoteStream);
        });
        call.on("close", () => {
          onInterpreterDisconnected();
        });
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            viewersCount: prevState.viewersCount + 1,
          };
        });
        call.on("stream", (remoteStream) => {
          onUserStream(remoteStream);
        });
        call.on("close", () => {
          onUserDisconnected();
        });
      }
    },
    [
      myStream,
      onGuideDisconnected,
      onGuideStream,
      onInterpreterDisconnected,
      onInterpreterStream,
      onUserDisconnected,
      onUserStream,
    ]
  );

  const onViewerAdded = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        viewersCount: prevState.viewersCount + 1,
      };
    });
  }, []);
  const onViewerSubtracted = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        viewersCount: prevState.viewersCount - 1,
      };
    });
  }, []);

  const onAnswer = useCallback(() => {
    peer.on("call", onPeerCall);
  }, [peer, onPeerCall]);

  const onCall = useCallback(async () => {
    const stream = await myStream();
    if (role === "guide") {
      onGuideStream(stream);
    } else if (role === "interpreter") {
      onInterpreterStream(stream);
    }
    const callOption: CallOption = {
      metadata: {
        role,
      },
    };
    socket.on(SocketEvents.GuideConnected, (userId) => {
      if (stream) {
        const call = peer.call(userId, stream, callOption);
        call.on("stream", (remoteStream) => {
          onGuideStream(remoteStream);
        });
        call.on("close", () => {
          onGuideDisconnected();
        });
        socket.emit(
          SocketEvents.ChangeSpeakerLanguage,
          speakerLanguage.current
        );
      }
    });
    socket.on(SocketEvents.InterpreterConnected, (userId) => {
      if (stream) {
        const call = peer.call(userId, stream, callOption);
        call.on("stream", (remoteStream) => {
          onInterpreterStream(remoteStream);
        });
        call.on("close", () => {
          onInterpreterDisconnected();
        });
      }
    });
    socket.on(SocketEvents.UserConnected, (userId) => {
      if (stream) {
        const call = peer.call(userId, stream, callOption);
        call.on("stream", (remoteStream) => {
          onUserStream(remoteStream);
        });
        call.on("close", () => {
          onUserDisconnected();
        });
        onViewerAdded();
      }
    });
    socket.on(
      SocketEvents.UserDisconnected,
      (userId, disconnectedUserRole: Role) => {
        if (disconnectedUserRole === "guide") {
          onGuideDisconnected();
        } else if (disconnectedUserRole === "interpreter") {
          onInterpreterDisconnected();
        } else {
          onViewerSubtracted();
          onUserDisconnected();
        }
      }
    );
  }, [
    myStream,
    onGuideDisconnected,
    onGuideStream,
    onInterpreterDisconnected,
    onInterpreterStream,
    onUserDisconnected,
    onUserStream,
    onViewerAdded,
    onViewerSubtracted,
    peer,
    role,
    socket,
  ]);

  const onSpeechRecognition = useCallback(() => {
    if (!isFullyStopped.current && role === "guide") {
      // @ts-ignore
      window.SpeechRecognition =
        // @ts-ignore
        window.SpeechRecognition || webkitSpeechRecognition;
      // @ts-ignore
      recognition.current = new webkitSpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = speakerLanguage.current;
      recognition.current.maxAlternatives = 1;

      recognition.current.onsoundstart = () => {
        setStatus(SpeechStatus.PROCESSING);
      };
      recognition.current.onnomatch = () => {
        setStatus(SpeechStatus.NO_MATCH);
      };
      recognition.current.onerror = (err: any) => {
        if (!isFullyStopped.current) {
          setStatus(SpeechStatus.ERROR);
          if (!isRecognizing.current) onSpeechRecognition();
        }
      };
      recognition.current.onsoundend = () => {
        setStatus(SpeechStatus.IN_BETWEEN);
        if (!isFullyStopped.current) onSpeechRecognition();
      };
      recognition.current.onresult = (e: {
        resultIndex: number;
        results: { isFinal: boolean; 0: { transcript: string } }[];
      }) => {
        const result = e.results[e.results.length - 1];
        const { transcript } = result[0];
        let isResetNeeded = false;
        if (result.isFinal) {
          const text =
            transcript + (speakerLanguage.current === "ja-JP" ? "。" : ".");
          if (transcript) socket.emit(SocketEvents.MessageSend, text);
          setState((prevState) => ({
            ...prevState,
            interimResult: "",
            messages: [
              ...prevState.messages,
              {
                id: Date.now(),
                text,
              },
            ],
            resultText: prevState.resultText + transcript,
          }));
          isResetNeeded = true;
          setTimeoutForClearText();
          isRecognizing.current = false;
        } else {
          let concatTranscripts = "";
          clearTimeoutForClearText();
          // If continuous: e.results will include previous speech results: need to start loop at the current event resultIndex for proper concatenation
          for (let i = e.resultIndex; i < e.results.length; i++) {
            concatTranscripts += e.results[i][0].transcript;
          }
          socket.emit(SocketEvents.InterimMessageSend, concatTranscripts);
          setState((prevState) => ({
            ...prevState,
            interimResult: concatTranscripts,
          }));
          isRecognizing.current = true;
        }
        if (isResetNeeded) onSpeechRecognition();
      };
      setTimeoutForClearText();
      setStatus(SpeechStatus.READY);
      isRecognizing.current = false;
      recognition.current.start();
    }
  }, [setTimeoutForClearText, socket, role]);

  const onSocketListeners = useCallback(() => {
    socket.on(SocketEvents.MessageReceived, (message) => {
      setState((prevState) => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            id: Date.now(),
            text: message,
          },
        ],
        resultText: prevState.resultText + message,
        interimResult: "",
      }));
      setTimeoutForClearText();
    });
    socket.on(SocketEvents.InterimMessageReceived, (message) => {
      setState((prevState) => ({
        ...prevState,
        interimResult: message,
      }));
    });
    socket.on(SocketEvents.GuideLanguageChanged, (language) => {
      if (recognition.current) {
        isFullyStopped.current = true;
        recognition.current.stop();
      }
      speakerLanguage.current = language;
      setTimeout(() => {
        isFullyStopped.current = false;
        onSpeechRecognition();
      }, 100);
      forceUpdate();
    });
  }, [forceUpdate, onSpeechRecognition, setTimeoutForClearText, socket]);

  useEffect(() => {
    peer.on("open", (id) => {
      setState((prevState) => ({
        ...prevState,
        peerId: id,
      }));
    });
    document.onfullscreenchange = (e) => {
      if (document.fullscreenElement) {
        setState((prevState) => ({
          ...prevState,
          isFullscreen: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isFullscreen: false,
        }));
      }
    };
    return () => {
      peer.disconnect();
      document.onfullscreenchange = null;
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [peer, socket]);

  const onStopStream = useCallback(() => {
    if (stream.current) {
      stream.current.getTracks().forEach((track) => {
        track.stop();
      });
      stream.current = undefined;
    }
    if (recognition.current) {
      console.log("CALLED");
      isFullyStopped.current = true;
      recognition.current.abort();
    }
    onGuideDisconnected();
    onInterpreterDisconnected();
    onUserDisconnected();
    peer.off("call", onPeerCall);
    socket.off();
    socket.disconnect();
    setState((prevState) => ({
      ...prevState,
      viewersCount: 0,
      isStreaming: false,
    }));
    setStatus(SpeechStatus.UNKNOWN);
  }, [
    onGuideDisconnected,
    onInterpreterDisconnected,
    onPeerCall,
    onUserDisconnected,
    peer,
    socket,
  ]);

  const onSpeakerLanguageChange = useCallback(
    (e: string) => {
      const selectedLanguage = LanguageOptions.find((item) => item.value === e);
      let languageCode = e;
      if (selectedLanguage?.children) {
        languageCode = selectedLanguage.children?.[0].value;
      }
      socket.emit(SocketEvents.ChangeSpeakerLanguage, languageCode);
      speakerLanguage.current = languageCode;
      forceUpdate();
    },
    [socket, forceUpdate]
  );

  const onStartStream = useCallback(async () => {
    const { peerId } = state;
    socket.connect();
    await onCall();
    await onAnswer();
    if (role === "guide") {
      isFullyStopped.current = false;
      onSpeechRecognition();
    }
    onSocketListeners();
    socket.emit(SocketEvents.JoinRoom, roomId, peerId, role);
    socket.on("error", (e) => {
      console.error(e);
    });
    setState((prevState) => ({
      ...prevState,
      isStreaming: true,
    }));
  }, [
    socket,
    state,
    onAnswer,
    onCall,
    roomId,
    onSpeechRecognition,
    role,
    onSocketListeners,
  ]);

  const value = {
    state: state,
    setState,
    methods: {
      onSpeakerLanguageChange: onSpeakerLanguageChange,
      onStartStream: onStartStream,
      onStopStream: onStopStream,
    },
    refs: {
      stream,
      container: container,
      signInterpreter: signInterpreter,
      tourGuideVideo: tourGuideVideo,
      userVideo: userVideo,
      speakerLanguage,
    },
    role,
    status,
  };
  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export const useVideoProvider = () => useContext(VideoContext);

export default VideoCallProvider;
