import { useOnShowChange } from "@app/pages/VideoCall/context/hooks/useOnShowChange";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Form, Switch } from "antd";
import React from "react";

const ShowSwitch = () => {
  const {
    state: {
      subtitle: { show },
    },
  } = useVideoProvider();
  const onShowChange = useOnShowChange();

  return (
    <Form.Item
      label="Show subtitle"
      labelAlign='left'
      labelCol={{
        lg: 24,
        md: 12,
        sm: 12,
      }}
      wrapperCol={{
        lg: {
          offset: 6,
          span: 12,
        },
        md: 12,
        sm: 12,
      }}
    >
      <Switch checked={show} onChange={onShowChange} />
    </Form.Item>
  );
};

export default ShowSwitch;
