import { Status } from "@app/pages/VideoCall/context/types";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import React from "react";

const SpeechState = () => {
  const { status } = useVideoProvider();

  return (
    <div translate="no" className={`status status-pos ${status}`}>
      {Status[status]}
    </div>
  );
};

export default SpeechState;
