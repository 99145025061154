import { useVh } from "@app/hooks/useVh";
import { PositionOptions } from "@app/pages/VideoCall/config/PositionOptions";
import { useOnPositionChange } from "@app/pages/VideoCall/context/hooks/useOnPositionChange";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Form, Select } from "antd";
import React from "react";

const PositionSelect = () => {
  const {
    state: {
      labelCol,
      subtitle: { position },
    },
  } = useVideoProvider();
  const onPositionChange = useOnPositionChange();
  const vh = useVh();

  return (
    <Form.Item label="Subtitle position" labelCol={labelCol}>
      <Select
        options={PositionOptions}
        value={position}
        onChange={onPositionChange}
        listItemHeight={3 * vh}
        listHeight={30 * vh}
      />
    </Form.Item>
  );
};

export default PositionSelect;
