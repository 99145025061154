export const githubPickerColors = [
  "#FFFFFF",
  "#000000",
  "#B80000",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
];

export const githubPickerStyles = {
  default: {
    card: {
      width: "100%",
    },
  },
};
