import { AuthProvider } from "@app/context/AuthProvider";
import { useWindowHeight } from "@app/hooks/useWindowHeight";
import Router from "@app/router/Router";
import React from "react";
import { BrowserRouter } from "react-router-dom";

const Root = () => {
  useWindowHeight();

  return (
    <AuthProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Root;
