import { CheckboxIcon, CopyIcon } from "@app/assets/icon";
import { Button, Typography } from "antd";
import React, { FC } from "react";

const CopyButton: FC<{ icon: React.ReactNode }> = ({ icon }) => {
  return (
    <Button
      type="primary"
      icon={icon}
      ghost
      size="large"
      className="copy-link-button"
    >
      Guide Link
    </Button>
  );
};

const CopyLink: FC<{
  link: string;
}> = ({ link }) => {
  return (
    <Typography.Link
      className="copy-link"
      copyable={{
        text: link,
        icon: [
          <CopyButton icon={<CopyIcon />} />,
          <CopyButton icon={<CheckboxIcon />} />,
        ],
      }}
    ></Typography.Link>
  );
};

export default CopyLink;
