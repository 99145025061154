import React from "react";
import { Layout } from "antd";
import SpeakerLanguage from "@app/pages/VideoCall/components/SpeakerLanguage";
import GoogleTranslate from "@app/pages/VideoCall/components/GoogleTranslate";
import ResetSettings from "@app/pages/VideoCall/components/ResetSettings";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import CopyLink from "@app/pages/VideoCall/components/CopyLink";
import DownloadTranscript from "@app/pages/VideoCall/components/DownloadTranscript";
const { Sider } = Layout;

const LeftSider = () => {
  const {
    role,
    state: { settingsClassName },
  } = useVideoProvider();

  return (
    <Sider theme="light" width={250} className="left-sider-container">
      <div
        className={`left-sider ${settingsClassName}`}
        style={{
          display: role === "guide" ? "none" : undefined,
        }}
      >
        <SpeakerLanguage />
        <GoogleTranslate />
        <ResetSettings />
        <DownloadTranscript />
        <CopyLink
          link={`${window.location.host}${window.location.pathname}?role=guide`}
        />

      </div>
    </Sider>
  );
};

export default LeftSider;
