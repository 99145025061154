import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useMemo } from "react";

export const useBackgroundColor = () => {
  const {
    state: {
      subtitle: { backgroundColor },
    },
  } = useVideoProvider();

  return useMemo(() => {
    if (backgroundColor === "transparent") return backgroundColor;
    return `${backgroundColor}4D`;
  }, [backgroundColor]);
};
