import { UserIcon } from "@app/assets/icon";
import { Avatar } from "antd";
import React from "react";

const VideoPlaceholder = () => {
  return (
    <div className="no-video-container">
      <div className="no-video-top" />
      <Avatar icon={<UserIcon color="#2FA7DE" />} />
      <div className="no-video-bottom" />
    </div>
  );
};

export default VideoPlaceholder;
