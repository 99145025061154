import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import React from "react";

const ViewerAudio = () => {
  const {
    role,
    refs: { userVideo },
  } = useVideoProvider();

  return <audio ref={userVideo} muted={!role} hidden />;
};

export default ViewerAudio;
