import { getPosition } from "@app/pages/VideoCall/config/PositionOptions";
import { useBackgroundColor } from "@app/pages/VideoCall/context/hooks/useBackgroundColor";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import React from "react";

const SubtitleContainer = () => {
  const {
    state: {
      resultText,
      interimResult,
      subtitle: { size, show, font, color, position },
    },
  } = useVideoProvider();
  const { top, right, width, bottom, height } = getPosition(position);
  const subPlateColor = useBackgroundColor();

  return (
    <div
      className="transcript"
      style={{
        fontSize: `${size}px`,
        color,
        display: !show ? "none" : undefined,
        fontFamily: font,
        backgroundColor: subPlateColor,
        // Positioning variables
        top,
        right,
        width,
        bottom,
        height,
      }}
    >
      <div className="transcript-box">
        <span>{resultText}</span>
        <span translate="no">{interimResult}</span>
      </div>
    </div>
  );
};

export default SubtitleContainer;
