import { useEffect, useState } from "react";

export const useVh = () => {
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    const onResize = () => {
      setVh(window.innerHeight * 0.01);
    };
    // We listen to the resize event
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return vh;
};
