import { initialState } from "@app/pages/VideoCall/context/initialState";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useCallback } from "react";

export const useOnResetSettings = () => {
  const { setState } = useVideoProvider();
  return useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      subtitle: initialState.subtitle,
    }));
  }, [setState]);
};
