import { useAuth } from "@app/context/AuthProvider";
import { MyRoutes } from "@app/router/routes";
import { AUTH_DISABLED } from "@app/utils/config";
import { getUserName } from "@app/utils/getUserName";
import { getUserRoles } from "@app/utils/getUserRoles";
import { Button, Result } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

const Home = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const attributes = auth?.state.userAttributes || [];
  const roles = getUserRoles(attributes);
  const username = getUserName(attributes);
  const onClick = useCallback(() => {
    navigate({
      pathname: MyRoutes.replace(MyRoutes.VideoCall, {
        roomId: Math.floor(Math.random() * 100000) + 1,
      }),
    });
  }, [navigate]);

  const isViewer = AUTH_DISABLED || roles.includes("viewer");
  return (
    <div className="dr-home">
      <Result
        status="success"
        title={`Welcome to Online Tour! ${username}`}
        extra={[
          isViewer && (
            <Button
              key="0"
              type="primary"
              onClick={onClick}
              size="large"
              className="dr-home-button"
            >
              Start an Online Tour
            </Button>
          ),
          !!attributes.length && (
            <Button
              key="2"
              onClick={async () => {
                await auth?.methods.onSignout();
                navigate(MyRoutes.Login);
              }}
              size="large"
              className="dr-home-button"
            >
              Logout
            </Button>
          ),
        ]}
      />
    </div>
  );
};

export default Home;
