import { parseJson } from "@app/utils/parseJson";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

type Role = "viewer" | "guide" | "admin";

export const getUserRoles = (attributes: CognitoUserAttribute[]): Role[] => {
  const roleString = attributes.find((item) => item.Name === "custom:role");
  const roles = parseJson(roleString?.Value || "");
  if (roles && Array.isArray(roles)) {
    return roles;
  }
  return [];
};
