import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useCallback } from "react";

export const useOnSettingsToggle = () => {
  const { setState } = useVideoProvider();
  return useCallback(() => {
    setState((prevState) => {
      if (prevState.settingsClassName === "fade-in") {
        return { ...prevState, settingsClassName: "" };
      } else {
        return {
          ...prevState,
          settingsClassName: "fade-in",
        };
      }
    });
  }, [setState]);
};
