import { MyRoutes } from "@app/router/routes";
import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found">
      <Result
        status="404"
        title="Page not found"
        subTitle="We're sorry, the page you requested could not be found."
        extra={
          <Link to={MyRoutes.Home}>
            <Button type="primary" size='large'>Home</Button>
          </Link>
        }
      />
    </div>
  );
};

export default NotFound;
