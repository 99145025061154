import { useAuth } from "@app/context/AuthProvider";
import { MyRoutes } from "@app/router/routes";
import { AUTH_DISABLED } from "@app/utils/config";
import { FC, Fragment } from "react";
import { Navigate, useLocation } from "react-router";

const ProtectedRoute: FC = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  // TODO: Remove this line
  if (AUTH_DISABLED) return <Fragment>{children}</Fragment>;
  if (!auth?.state.user)
    return <Navigate to={MyRoutes.Login} state={{ from: location }} />;
  return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;
