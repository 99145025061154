import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useCallback } from "react";

export const useOnFullScreen = () => {
  const {
    refs: { container },
  } = useVideoProvider();

  return useCallback(async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      if (container.current) {
        container.current.requestFullscreen?.();
      }
    }
  }, [container]);
};
