import { useCallback, useRef } from "react";

export const useUserVideoRef = () => {
  const userVideo = useRef<HTMLVideoElement>(null);

  const onUserDisconnected = useCallback(() => {
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
  }, []);

  const onUserStream = useCallback((stream: MediaStream) => {
    if (userVideo.current) {
      userVideo.current.srcObject = stream;
      userVideo.current.onloadedmetadata = (e) => {
        if (userVideo.current) userVideo.current.play().catch(console.error);
      };
    }
  }, []);

  return {
    userVideo,
    onUserDisconnected,
    onUserStream,
  };
};
