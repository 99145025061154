import { EyeIcon } from "@app/assets/icon";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import React from "react";

const ViewerCount = () => {
  const {
    state: { viewersCount },
  } = useVideoProvider();

  return (
    <div className="viewers-count viewers-count-pos">
      <EyeIcon />
      {viewersCount}
    </div>
  );
};

export default ViewerCount;
