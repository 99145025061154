import { useOnSizeChange } from "@app/pages/VideoCall/context/hooks/useOnSizeChange";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Form, Slider } from "antd";
import React from "react";

const FontSizeSelect = () => {
  const {
    state: {
      labelCol,
      subtitle: { size },
    },
  } = useVideoProvider();
  const onSizeChange = useOnSizeChange();

  return (
    <Form.Item label="Subtitle size" labelCol={labelCol}>
      <Slider min={16} max={100} onChange={onSizeChange} value={size} />
    </Form.Item>
  );
};

export default FontSizeSelect;
