import { useAuth } from "@app/context/AuthProvider";
import { MyRoutes } from "@app/router/routes";
import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

const NotAuthenticated: FC = ({ children }) => {
  const auth = useAuth();
  const { state } = useLocation();

  if (auth?.state.user) return <Navigate to={state?.from || MyRoutes.Home} />;

  return <React.Fragment>{children}</React.Fragment>;
};

export default NotAuthenticated;
