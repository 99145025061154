import React from "react";
import { Layout } from "antd";
import {
  FontSelect,
  FontSizeSelect,
  PositionSelect,
  ShowSwitch,
  SubtitleBackgroundPicker,
  SubtitleColorPicker,
} from "@app/pages/VideoCall/components";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";

const { Sider } = Layout;

const RightSider = () => {
  const {
    state: { settingsClassName },
    role,
  } = useVideoProvider();

  return (
    <Sider
      theme="light"
      className={`right-sider-container ${settingsClassName ? "overflow" : ""}`}
    >
      <div
        className={`right-sider ${settingsClassName}`}
        style={{
          display: role === "guide" ? "none" : undefined,
        }}
      >
        <ShowSwitch />
        <PositionSelect />
        <FontSizeSelect />
        <FontSelect />
        <SubtitleColorPicker />
        <SubtitleBackgroundPicker />
      </div>
    </Sider>
  );
};

export default RightSider;
