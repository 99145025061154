import {
  MuteIcon,
  SettingsIcon,
  StopIcon,
  StreamIcon,
  UnmuteIcon,
} from "@app/assets/icon";
import { useOnMute } from "@app/pages/VideoCall/context/hooks/useOnMute";
import { useOnSettingsToggle } from "@app/pages/VideoCall/context/hooks/useOnSettingsToggle";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import SettingsModal from "@app/pages/VideoCall/layout/SettingsModal";
import { Button, Space } from "antd";
import React from "react";

const MyFooter = () => {
  const {
    role,
    state: { isStreaming, isMute, settingsClassName },
    methods: { onStartStream, onStopStream },
  } = useVideoProvider();
  const onSettingsToggle = useOnSettingsToggle();
  const onMute = useOnMute();

  const startStreamText = role ? "Start streaming" : "Start watching";
  const streamButtonText = isStreaming ? "Stop" : startStreamText;

  const streamButtonIcon = isStreaming ? <StopIcon /> : <StreamIcon />;
  const micIcon = isMute ? <UnmuteIcon /> : <MuteIcon />;

  return (
    <div className="footer">
      <Space size="large">
        {!role && isStreaming && (
          <Button
            icon={micIcon}
            onClick={onMute}
            className="mute-button"
            danger={isMute}
            type={isMute ? "default" : "primary"}
            ghost={!isMute}
            size="large"
          />
        )}
        <Button
          type="primary"
          size="large"
          onClick={isStreaming ? onStopStream : onStartStream}
          danger={isStreaming}
          className="stream-button"
          icon={streamButtonIcon}
        >
          {streamButtonText}
        </Button>
        {role !== "guide" && (
          <React.Fragment>
            <Button
              onClick={onSettingsToggle}
              icon={<SettingsIcon />}
              size="large"
              type={settingsClassName ? "primary" : "default"}
              ghost={!!settingsClassName}
              className="settings-button-lg"
            >
              Settings
            </Button>
            <SettingsModal />
          </React.Fragment>
        )}
      </Space>
    </div>
  );
};

export default MyFooter;
