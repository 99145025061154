import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useCallback } from "react";

export const useOnSizeChange = () => {
  const { setState } = useVideoProvider();
  return useCallback(
    (size: number) => {
      setState((prevState) => ({
        ...prevState,
        subtitle: {
          ...prevState.subtitle,
          size,
        },
      }));
    },
    [setState]
  );
};
