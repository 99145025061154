import { SetVideoState } from "@app/pages/VideoCall/context/types";
import { useCallback, useRef } from "react";

export const useInterpreterVideoRef = (setState: SetVideoState) => {
  const signInterpreter = useRef<HTMLVideoElement>(null);

  const onInterpreterStream = useCallback((stream: MediaStream) => {
    if (signInterpreter.current) {
      setState((prevState) => ({
        ...prevState,
        interpreterVideoClassName: "",
      }));
      signInterpreter.current.srcObject = stream;
      signInterpreter.current.onloadedmetadata = (e) => {
        if (signInterpreter.current)
          signInterpreter.current.play().catch(console.error);
      };
    }
  }, [setState]);

  const onInterpreterDisconnected = useCallback(() => {
    if (signInterpreter.current) {
      setState((prevState) => ({
        ...prevState,
        interpreterVideoClassName: "hide",
      }));
      signInterpreter.current.srcObject = null;
    }
  }, [setState]);

  return {
    signInterpreter,
    onInterpreterStream,
    onInterpreterDisconnected,
  };
};
