export const LanguageOptions = [
  {
    value: "ja-JP",
    title: "日本語",
  },
  {
    value: "en",
    title: "English",
    children: [
      {
        value: "en-US",
        title: "United States",
      },
      {
        value: "en-AU",
        title: "Australia",
      },
      {
        value: "en-CA",
        title: "Canada",
      },
      {
        value: "en-IN",
        title: "India",
      },
      {
        value: "en-KE",
        title: "Kenya",
      },
      {
        value: "en-TZ",
        title: "Tanzania",
      },
      {
        value: "en-GH",
        title: "Ghana",
      },
      {
        value: "en-NZ",
        title: "New Zealand",
      },
      {
        value: "en-NG",
        title: "Nigeria",
      },
      {
        value: "en-ZA",
        title: "South Africa",
      },
      {
        value: "en-PH",
        title: "Philippines",
      },
      {
        value: "en-GB",
        title: "United Kingdom",
      },
    ],
  },
  {
    value: "af-ZA",
    title: "Afrikaans",
  },
  {
    value: "am-ET",
    title: "አማርኛ",
  },
  {
    value: "az-AZ",
    title: "Azərbaycanca",
  },
  {
    value: "bn",
    title: "বাংলা",
    children: [
      {
        value: "bn-BD",
        title: "বাংলাদেশ",
      },
      {
        value: "bn-IN",
        title: "ভারত",
      },
    ],
  },
  {
    value: "id-ID",
    title: "Bahasa Indonesia",
  },
  {
    value: "ms-MY",
    title: "Bahasa Melayu",
  },
  {
    value: "ca-ES",
    title: "Català",
  },
  {
    value: "cs-CZ",
    title: "Čeština",
  },
  {
    value: "da-DK",
    title: "Dansk",
  },
  {
    value: "de-DE",
    title: "Deutsch",
  },
  {
    value: "es",
    title: "Español",
    children: [
      {
        value: "es-ES",
        title: "España",
      },
      {
        value: "es-AR",
        title: "Argentina",
      },
      {
        value: "es-BO",
        title: "Bolivia",
      },
      {
        value: "es-CL",
        title: "Chile",
      },
      {
        value: "es-CO",
        title: "Colombia",
      },
      {
        value: "es-CR",
        title: "Costa Rica",
      },
      {
        value: "es-EC",
        title: "Ecuador",
      },
      {
        value: "es-SV",
        title: "El Salvador",
      },
      {
        value: "es-US",
        title: "Estados Unidos",
      },
      {
        value: "es-GT",
        title: "Guatemala",
      },
      {
        value: "es-HN",
        title: "Honduras",
      },
      {
        value: "es-MX",
        title: "México",
      },
      {
        value: "es-NI",
        title: "Nicaragua",
      },
      {
        value: "es-PA",
        title: "Panamá",
      },
      {
        value: "es-PY",
        title: "Paraguay",
      },
      {
        value: "es-PE",
        title: "Perú",
      },
      {
        value: "es-PR",
        title: "Puerto Rico",
      },
      {
        value: "es-DO",
        title: "República Dominicana",
      },
      {
        value: "es-UY",
        title: "Uruguay",
      },
      {
        value: "es-VE",
        title: "Venezuela",
      },
    ],
  },
  {
    value: "eu-ES",
    title: "Euskara",
  },
  {
    value: "fil-PH",
    title: "Filipino",
  },
  {
    value: "fr-FR",
    title: "Français",
  },
  {
    value: "jv-ID",
    title: "Basa Jawa",
  },
  {
    value: "gl-ES",
    title: "Galego",
  },
  {
    value: "gu-IN",
    title: "ગુજરાતી",
  },
  {
    value: "hr-HR",
    title: "Hrvatski",
  },
  {
    value: "zu-ZA",
    title: "IsiZulu",
  },
  {
    value: "is-IS",
    title: "Íslenska",
  },
  {
    value: "it",
    title: "Italiano",
    children: [
      {
        value: "it-IT",
        title: "Italia",
      },
      {
        value: "it-CH",
        title: "Svizzera",
      },
    ],
  },
  {
    value: "kn-IN",
    title: "ಕನ್ನಡ",
  },
  {
    value: "km-KH",
    title: "ភាសាខ្មែរ",
  },
  {
    value: "lv-LV",
    title: "Latviešu",
  },
  {
    value: "lt-LT",
    title: "Lietuvių",
  },
  {
    value: "ml-IN",
    title: "മലയാളം",
  },
  {
    value: "mr-IN",
    title: "मराठी",
  },
  {
    value: "hu-HU",
    title: "Magyar",
  },
  {
    value: "lo-LA",
    title: "ລາວ",
  },
  {
    value: "nl-NL",
    title: "Nederlands",
  },
  {
    value: "ne-NP",
    title: "नेपाली भाषा",
  },
  {
    value: "nb-NO",
    title: "Norsk bokmål",
  },
  {
    value: "pl-PL",
    title: "Polski",
  },
  {
    value: "pt",
    title: "Português",
    children: [
      {
        value: "pt-BR",
        title: "Brasil",
      },
      {
        value: "pt-PT",
        title: "Portugal",
      },
    ],
  },
  {
    value: "ro-RO",
    title: "Română",
  },
  {
    value: "si-LK",
    title: "සිංහල",
  },
  {
    value: "sl-SI",
    title: "Slovenščina",
  },
  {
    value: "su-ID",
    title: "Basa Sunda",
  },
  {
    value: "sk-SK",
    title: "Slovenčina",
  },
  {
    value: "fi-FI",
    title: "Suomi",
  },
  {
    value: "sv-SE",
    title: "Svenska",
  },
  {
    value: "sw",
    title: "Kiswahili",
    children: [
      {
        value: "sw-TZ",
        title: "Tanzania",
      },
      {
        value: "sw-KE",
        title: "Kenya",
      },
    ],
  },
  {
    value: "ka-GE",
    title: "ქართული",
  },
  {
    value: "hy-AM",
    title: "Հայերեն",
  },
  {
    value: "ta",
    title: "தமிழ்",
    children: [
      {
        value: "ta-IN",
        title: "இந்தியா",
      },
      {
        value: "ta-SG",
        title: "சிங்கப்பூர்",
      },
      {
        value: "ta-LK",
        title: "இலங்கை",
      },
      {
        value: "ta-MY",
        title: "மலேசியா",
      },
    ],
  },
  {
    value: "te-IN",
    title: "తెలుగు",
  },
  {
    value: "vi-VN",
    title: "Tiếng Việt",
  },
  {
    value: "tr-TR",
    title: "Türkçe",
  },
  {
    value: "ur",
    title: "اُردُو",
    children: [
      {
        value: "ur-PK",
        title: "پاکستان",
      },
      {
        value: "ur-IN",
        title: "بھارت",
      },
    ],
  },
  {
    value: "el-GR",
    title: "Ελληνικά",
  },
  {
    value: "bg-BG",
    title: "български",
  },
  {
    value: "ru-RU",
    title: "Pусский",
  },
  {
    value: "sr-RS",
    title: "Српски",
  },
  {
    value: "uk-UA",
    title: "Українська",
  },
  {
    value: "ko-KR",
    title: "한국어",
  },
  {
    value: "cmn",
    title: "中文",
    children: [
      {
        value: "cmn-Hans-CN",
        title: "普通话 (中国大陆)",
      },
      {
        value: "cmn-Hans-HK",
        title: "普通话 (香港)",
      },
      {
        value: "cmn-Hant-TW",
        title: "中文 (台灣)",
      },
      {
        value: "yue-Hant-HK",
        title: "粵語 (香港)",
      },
    ],
  },
  {
    value: "hi-IN",
    title: "हिन्दी",
  },
  {
    value: "th-TH",
    title: "ภาษาไทย",
  },
];
