import { ExitFullScreenIcon, FullScreenIcon } from "@app/assets/icon";
import { useOnFullScreen } from "@app/pages/VideoCall/context/hooks/useOnFullScreen";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Button } from "antd";
import React from "react";

const FullScreenButton = () => {
  const {
    state: { isFullscreen },
  } = useVideoProvider();
  const onFullScreen = useOnFullScreen();

  return (
    <Button
      className="fullscreen-button fullscreen-button-pos"
      size="large"
      ghost
      onClick={onFullScreen}
      icon={isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
    />
  );
};

export default FullScreenButton;
