import { Layout } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { MyRoutes } from "@app/router/routes";
import { BackIcon } from "@app/assets/icon";

const { Header } = Layout;

const MyHeader = () => {
  return (
    <Header className="header">
      <Link to={MyRoutes.Home} className="back-button">
        <BackIcon />
      </Link>
    </Header>
  );
};

export default MyHeader;
