export const MyRoutes = {
  Root: "/",
  Home: "/app",
  Login: "/login",
  ForgotPassword: "/forgot_password",
  ChangeInitialPassword: "/change_initial_password",
  ResetPasswod: "/reset_password",
  VideoCall: "/video_call/:roomId",
  NotFound: "*",

  replace: (route: string, params: Record<string, string | number>): string => {
    let path = route;
    Object.entries(params).forEach(([key, value]) => {
      path = path.replace(`:${key}`, `${value}`);
    });
    return path;
  },
};
