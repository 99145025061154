const twoDigit = (arg: number) => {
  return arg < 10 ? `0${arg}` : arg;
};

export const formatDate = (arg: number) => {
  const date = new Date(arg);
  return `${date.getFullYear()}-${twoDigit(date.getMonth())}-${twoDigit(
    date.getDate()
  )} ${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}:${twoDigit(
    date.getSeconds()
  )}`;
};
