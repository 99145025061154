import Loading from "@app/components/Loading/Loading";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import LeftSider from "@app/pages/VideoCall/layout/LeftSider";
import MyFooter from "@app/pages/VideoCall/layout/MyFooter";
import MyHeader from "@app/pages/VideoCall/layout/MyHeader";
import RightSider from "@app/pages/VideoCall/layout/RightSider";
import VideoPlayer from "@app/pages/VideoCall/layout/video/VideoPlayer";
import ViewerAudio from "@app/pages/VideoCall/layout/video/ViewerAudio";
import { Layout } from "antd";

const VideoCallPage = () => {
  const {
    state: { peerId },
  } = useVideoProvider();

  if (!peerId) return <Loading />;

  return (
    <Layout>
      <ViewerAudio />
      <LeftSider />
      <Layout>
        <MyHeader />
        <VideoPlayer />
        <MyFooter />
      </Layout>
      <RightSider />
    </Layout>
  );
};

export default VideoCallPage;
