import VideoCallProvider from "@app/pages/VideoCall/context/VideoProvider";
import VideoCallPage from "@app/pages/VideoCall/VideoCallPage";
import { MyRoutes } from "@app/router/routes";
import { Navigate, useParams } from "react-router";

const VideoCallContainer = () => {
  const params = useParams();
  if (!params.roomId) return <Navigate to={MyRoutes.Home} />;
  return (
    <VideoCallProvider roomId={params.roomId}>
      <VideoCallPage />
    </VideoCallProvider>
  );
};

export default VideoCallContainer;
