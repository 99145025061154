import { FontOptions } from "@app/pages/VideoCall/config/FontOptions";
import {
  VideoCallState
} from "@app/pages/VideoCall/context/types";

export const initialState: VideoCallState = {
  isFullscreen: false,
  isStreaming: false,
  messages: [],
  interimResult: "",
  settingsClassName: "",
  peerId: undefined,
  guideVideoClassName: "hide",
  interpreterVideoClassName: "hide",
  resultText: "",
  isMute: false,
  viewersCount: 0,
  subtitle: {
    show: true,
    color: "#FFFFFF",
    backgroundColor: "#000000",
    position: "Bottom",
    size: 34,
    font: FontOptions[0].value,
  },
  labelCol: {
    span: 24,
  },
};
