import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { useCallback } from "react";
import { ColorResult } from "react-color";

export const useOnColorChange = () => {
  const { setState } = useVideoProvider();
  return useCallback(
    (e: ColorResult) => {
      setState((prevState) => ({
        ...prevState,
        subtitle: {
          ...prevState.subtitle,
          color: e.hex,
        },
      }));
    },
    [setState]
  );
};
