import {
  githubPickerColors,
  githubPickerStyles,
} from "@app/pages/VideoCall/config/GithubPickerOptions";
import { useOnColorChange } from "@app/pages/VideoCall/context/hooks/useOnColorChange";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Form } from "antd";
import React from "react";
import { GithubPicker } from "react-color";

const SubtitleColorPicker = () => {
  const {
    state: {
      subtitle: { color },
      labelCol,
    },
  } = useVideoProvider();
  const onColorChange = useOnColorChange();

  return (
    <Form.Item label="Subtitle color" labelCol={labelCol}>
      <GithubPicker
        color={color}
        onChange={onColorChange}
        onChangeComplete={onColorChange}
        colors={[...githubPickerColors, "#5300EB"]}
        styles={githubPickerStyles}
        triangle={"hide"}
      />
    </Form.Item>
  );
};

export default SubtitleColorPicker;
