import NotAuthenticated from "@app/router/NotAuthenticated";
import ProtectedRoute from "@app/router/ProtectedRoute";
import { MyRouteObject } from "@app/router/types";
import { RouteObject, useRoutes } from "react-router";

export const useMyRoutes = (myRoutes: MyRouteObject[]) => {
  const routes: RouteObject[] = myRoutes.map((route) => {
    let element = route.element;
    if (route.isProtected) {
      element = <ProtectedRoute>{route.element}</ProtectedRoute>;
    } else if (route.notAuthenticated) {
      element = <NotAuthenticated>{route.element}</NotAuthenticated>;
    }
    return {
      path: route.path,
      element,
    };
  });
  const element = useRoutes(routes);

  return element;
};
