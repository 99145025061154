import { ColProps } from "antd";
import { RefObject } from "react";

export enum SpeechStatus {
  UNKNOWN = "UNKNOWN",
  READY = "READY",
  PROCESSING = "PROCESSING",
  NO_MATCH = "NO_MATCH",
  ERROR = "ERROR",
  IN_BETWEEN = "IN_BETWEEN",
}

export enum SocketEvents {
  MessageReceived = "message-received",
  InterimMessageReceived = "interim-received",
  GuideConnected = "guide-connected",
  InterpreterConnected = "interpreter-connected",
  UserConnected = "user-connected",
  UserDisconnected = "user-disconnected",
  ChangeSpeakerLanguage = "change-guide-language",
  GuideLanguageChanged = "guide-language-changed",
  JoinRoom = "join-room",
  MessageSend = "message-send",
  InterimMessageSend = "interim-send",
}

export type VideoCallState = {
  messages: {
    id: number;
    text: string;
  }[];
  interimResult: string;
  isFullscreen: boolean;
  settingsClassName: string;
  guideVideoClassName: string;
  interpreterVideoClassName: string;
  peerId: undefined | string;
  isStreaming: boolean;
  resultText: string;
  isMute: boolean;
  viewersCount: number;
  subtitle: {
    show: boolean;
    position: string;
    size: number;
    color: string;
    backgroundColor: string;
    font: string;
  };
  labelCol: ColProps;
};

export type VideoCallRefs = {
  stream: RefObject<MediaStream | undefined>;
  container: RefObject<HTMLDivElement>;
  signInterpreter: RefObject<HTMLVideoElement>;
  tourGuideVideo: RefObject<HTMLVideoElement>;
  userVideo: RefObject<HTMLVideoElement>;
  speakerLanguage: RefObject<string>;
};

export type SetVideoState = (
  value: React.SetStateAction<VideoCallState>
) => void;

export type VideoCallMethods = {
  onSpeakerLanguageChange: (e: string) => void;
  onStartStream: () => Promise<void>;
  onStopStream: () => void;
};

export const Status: Record<SpeechStatus, string> = {
  [SpeechStatus.UNKNOWN]: "読み込み中",
  [SpeechStatus.READY]: "待機中",
  [SpeechStatus.PROCESSING]: "認識中...",
  [SpeechStatus.NO_MATCH]: "音声を認識できませんでした",
  [SpeechStatus.ERROR]: "エラー",
  [SpeechStatus.IN_BETWEEN]: "停止中",
};
