export const FontOptions = [
  {
    value: `'Noto Sans JP', sans-serif`,
    label: "Noto Sans JP",
  },
  {
    value: `'BIZ UDゴシック', 'BIZ UDGothic', 'Noto Sans JP', sans-serif`,
    label: "BIZ UD ゴシック（Windows 10）",
  },
  {
    value: `'BIZ UD明朝', 'BIZ UDMincho', 'Noto Sans JP', sans-serif`,
    label: "BIZ UD 明朝（Windows 10）",
  },
  {
    value: `游ゴシック体, 'Yu Gothic', YuGothic, sans-serif`,
    label: "游ゴシック",
  },
  {
    value: `'メイリオ', 'Meiryo', 'Noto Sans JP', sans-serif`,
    label: "メイリオ",
  },
  {
    value: `'HGS創英角ﾎﾟｯﾌﾟ体', 'Noto Sans JP', sans-serif`,
    label: "ポップ体（Windows）",
  },
  {
    value: `sans-serif`,
    label: "ゴシック体（ブラウザ標準）",
  },
  {
    value: `serif`,
    label: "明朝体（ブラウザ標準）",
  },
];
