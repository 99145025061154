import {
  githubPickerColors,
  githubPickerStyles,
} from "@app/pages/VideoCall/config/GithubPickerOptions";
import { useOnBackgroundColorChange } from "@app/pages/VideoCall/context/hooks/useOnBackgroundColorChange";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Form } from "antd";
import React from "react";
import { GithubPicker } from "react-color";

const SubtitleBackgroundPicker = () => {
  const {
    state: {
      subtitle: { backgroundColor },
      labelCol,
    },
  } = useVideoProvider();
  const onBackgroundColorChange = useOnBackgroundColorChange()

  return (
    <Form.Item label="Subtitle background color" labelCol={labelCol}>
      <GithubPicker
        color={backgroundColor}
        onChange={onBackgroundColorChange}
        colors={["transparent", ...githubPickerColors]}
        styles={githubPickerStyles}
        triangle={"hide"}
      />
    </Form.Item>
  );
};

export default SubtitleBackgroundPicker;
