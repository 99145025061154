import { SettingsIcon } from "@app/assets/icon";
import {
  FontSelect,
  FontSizeSelect,
  GoogleTranslate,
  PositionSelect,
  ResetSettings,
  ShowSwitch,
  SpeakerLanguage,
  SubtitleBackgroundPicker,
  SubtitleColorPicker,
} from "@app/pages/VideoCall/components";
import { Button, Modal } from "antd";
import React, { useState } from "react";

const SettingsModal = () => {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <Button
        onClick={() => setVisible(true)}
        icon={<SettingsIcon />}
        size="large"
        type={"default"}
        className="settings-button-sm"
      >
        Settings
      </Button>
      <Modal
        bodyStyle={{ overflowX: "scroll" }}
        visible={visible}
        onCancel={() => setVisible(false)}
        style={{ top: 20 }}
        onOk={() => setVisible(false)}
        maskClosable
        destroyOnClose={false}
      >
        <SpeakerLanguage />
        <GoogleTranslate />
        <ResetSettings />
        <ShowSwitch />
        <PositionSelect />
        <FontSizeSelect />
        <FontSelect />
        <SubtitleColorPicker />
        <SubtitleBackgroundPicker />
      </Modal>
    </React.Fragment>
  );
};

export default SettingsModal;
