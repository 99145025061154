import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { SelectValue } from "antd/lib/select";
import { useCallback } from "react";

export const useOnPositionChange = () => {
  const { setState } = useVideoProvider();

  return useCallback(
    (e: SelectValue) => {
      if (typeof e === "string")
        setState((prevState) => ({
          ...prevState,
          subtitle: {
            ...prevState.subtitle,
            position: e,
          },
        }));
    },
    [setState]
  );
};
