import { useAuth } from "@app/context/AuthProvider";
import { MyRoutes } from "@app/router/routes";
import { AUTH_DISABLED } from "@app/utils/config";
import React from "react";
import { Navigate } from "react-router-dom";

const RootRedirect = () => {
  const auth = useAuth();
  // TODO: Remove this line
  if (AUTH_DISABLED) return <Navigate to={MyRoutes.Home} />;
  if (!auth?.state.user) return <Navigate to={MyRoutes.Login} />;
  return <Navigate to={MyRoutes.Home} />;
};

export default RootRedirect;
