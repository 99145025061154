import React, { useEffect } from "react";
import { Form } from "antd";
import { useVh } from "@app/hooks/useVh";

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "ja",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        multilanguagePage: true,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const vh = useVh();
  useEffect(() => {
    const changeIFrameCss = () => {
      var iframe: any = document.querySelector(".goog-te-menu-frame");
      var style = document.createElement("style");
      style.textContent = `
      .goog-te-combo, .goog-te-banner *, .goog-te-ftab *, .goog-te-menu *, .goog-te-menu2 *, .goog-te-balloon * {
        font-size: ${vh * 2}px;
      }
      `;
      if (iframe) {
        iframe.contentDocument.head.appendChild(style);
        clearInterval(interval);
      }
    };
    const interval = setInterval(changeIFrameCss, 1000);
  }, [vh]);

  return (
    <Form.Item
      label="Translate To"
      labelCol={{
        span: 24,
      }}
    >
      <div id="google_translate_element"></div>
    </Form.Item>
  );
};

export default GoogleTranslate;
