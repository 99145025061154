import { ResetIcon } from "@app/assets/icon";
import { useOnResetSettings } from "@app/pages/VideoCall/context/hooks/useOnResetSettings";
import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { Button, Form } from "antd";
import React from "react";

const ResetSettings = () => {
  const {
    state: { labelCol },
  } = useVideoProvider();
  const onResetSettings = useOnResetSettings();

  return (
    <Form.Item labelCol={labelCol}>
      <Button
        type="primary"
        icon={<ResetIcon />}
        onClick={onResetSettings}
        block
        size="large"
        className="reset-settings-button"
      >
        Reset Settings
      </Button>
    </Form.Item>
  );
};

export default ResetSettings;
