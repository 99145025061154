import { useVideoProvider } from "@app/pages/VideoCall/context/VideoProvider";
import { formatDate } from "@app/utils/formatDate";
import { notification } from "antd";
import { useCallback } from "react";

export const useOnDownload = () => {
  const {
    state: { messages },
  } = useVideoProvider();

  const onDownload = useCallback(() => {
    let messageLog = ``;
    if (messages.length) {
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        const date = formatDate(message.id);
        messageLog += `${date}: ${message.text}\n`;
      }
      const a = document.createElement("a");
      a.href = "data:text/plain," + encodeURIComponent(messageLog);
      a.download = `log_${formatDate(Date.now())}.txt`;
      a.click();
    } else {
      notification.error({
        message: "No transcription text",
      });
    }
  }, [messages]);

  return onDownload;
};
