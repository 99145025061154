import Loading from "@app/components/Loading/Loading";
import NotFound from "@app/components/NotFound/NotFound";
import { useAuth } from "@app/context/AuthProvider";
import ChangeInitialPassword from "@app/pages/ChangeInitialPassword/ChangeInitialPassword";
import ForgotPassword from "@app/pages/ForgotPassword/ForgotPassword";
import Home from "@app/pages/Home/Home";
import Login from "@app/pages/Login/Login";
import ResetPassword from "@app/pages/ResetPassword/ResetPassword";
import VideoCall from "@app/pages/VideoCall/VideoCallContainer";
import RootRedirect from "@app/router/RootRedirect";
import { MyRoutes } from "@app/router/routes";
import { useMyRoutes } from "@app/router/useMyRoutes";
import { AUTH_DISABLED } from "@app/utils/config";

const Router = () => {
  const auth = useAuth();
  const routes = AUTH_DISABLED
    ? [
        {
          path: MyRoutes.Root,
          element: <RootRedirect />,
        },
        {
          path: MyRoutes.Home,
          element: <Home />,
        },
        {
          path: MyRoutes.VideoCall,
          element: <VideoCall />,
        },
        {
          path: MyRoutes.NotFound,
          element: <NotFound />,
        },
      ]
    : [
        {
          path: MyRoutes.Root,
          element: <RootRedirect />,
        },
        {
          path: MyRoutes.Home,
          element: <Home />,
          isProtected: true,
        },
        {
          path: MyRoutes.Login,
          element: <Login />,
          notAuthenticated: true,
        },
        {
          path: MyRoutes.ChangeInitialPassword,
          element: <ChangeInitialPassword />,
          notAuthenticated: true,
        },
        {
          path: MyRoutes.ResetPasswod,
          element: <ResetPassword />,
          notAuthenticated: true,
        },
        {
          path: MyRoutes.ForgotPassword,
          element: <ForgotPassword />,
          notAuthenticated: true,
        },
        {
          path: MyRoutes.VideoCall,
          element: <VideoCall />,
          isProtected: true,
        },
        {
          path: MyRoutes.NotFound,
          element: <NotFound />,
        },
      ];
  const element = useMyRoutes(routes);

  if (auth?.state.loading) return <Loading />;

  return element;
};

export default Router;
