import { SetVideoState } from "@app/pages/VideoCall/context/types";
import { useCallback, useRef } from "react";

export const useGuideVideoRef = (setState: SetVideoState) => {
  const tourGuideVideo = useRef<HTMLVideoElement>(null);
  const onGuideStream = useCallback(
    (stream: MediaStream) => {
      if (tourGuideVideo.current) {
        setState((prevState) => ({
          ...prevState,
          guideVideoClassName: "",
        }));
        tourGuideVideo.current.srcObject = stream;
        tourGuideVideo.current.onloadedmetadata = (e) => {
          if (tourGuideVideo.current) {
            tourGuideVideo.current.play().catch(console.error);
          }
        };
      }
    },
    [setState]
  );

  const onGuideDisconnected = useCallback(() => {
    if (tourGuideVideo.current) {
      setState((prevState) => ({
        ...prevState,
        guideVideoClassName: "hide",
      }));
      tourGuideVideo.current.srcObject = null;
    }
  }, [setState]);

  return {
    tourGuideVideo,
    onGuideStream,
    onGuideDisconnected,
  };
};
